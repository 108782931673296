.landing-area-products {
  background-image: url('../assets/backgroundProducts.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 62% top;
  height: calc(100vh - 64px);
}

.cta-area-products {
  margin-left: 72%;
  padding-top: 17%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
}

.row-container-product{
  display: flex;
    flex-direction: row;
    align-items: center;
}

.product-title {
  font-size: 2em;
  font-weight: 600;
  letter-spacing: 4px;
}

.product-subtitle {
  padding-top: 20px;
    margin-left: 82px;
    font-style: italic;
    font-weight: 400;
    color: #949799;
    width: 50%;
}

.flex-labels{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  position: absolute;
  bottom: 7%;
  left: 10%;
}
.label-container{
  display: flex;
  flex-direction: row;
}

.label-icon-title{
  background-color: #11b267;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.label-icon-title{
  border: solid 4px #11b267;
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.label-icon{
  background-color: #11b267;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 15px;
}
.col-container-product{
  display: flex;
  flex-direction: column;
}
.label-title{
  font-size: 1.1em;
  font-weight: 500;
  padding-bottom: 0.1em;
}
.label-text{
  font-size: 0.9em;
  color: #afafaf;
}

.products-area {
  background-color: #f9fbfc;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-main-title {
  display: flex;
  justify-content: center;
  padding: 1.5em 0;
  font-size: 1.4em;
}

.products-flex-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
}

.product-col {
  display: flex;
  flex-direction: column;
  width: 15%;
}

.product-tile {
  padding: 10px 20px;
  box-shadow: 0px 0px 9px 5px rgba(217,217,217,0.76);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 60px!important;
  align-items: center;
  font-size: 0.9em;
  margin-bottom: 2em;
}

.product-tile-inside {
  width: 100%;
    border: solid 1px #d5d5d5;
    border-radius: 6px;
    text-align: center;
}

.group {
  height: 320px;
}

.products-title {
  color:#11b267;
  font-size: 1.3em;
  padding-bottom: 0.2em;
}

.line {
  background-color: #11b267;
  height: 1px;
  width: 35%;
  margin-bottom: 1em;
}

/* -------------------------------- */
/* -------------------------------- */
/* -------------------------------- */
/* -------------------------------- */
/* MOBILE */

@media (max-width: 600px) {
  .cta-area-products {
    padding-top: 10%;
    margin-left: 5%;
  }
  .landing-area-products{
    background-position: 17% top;
  }
  .flex-labels{
    flex-wrap: wrap;
    bottom: 7%;
    left: 10%;
    gap: 20%;
  }
  .label-container{
    padding-bottom: 0.5em;
  }
  .products-flex-container{
    flex-direction: column;
  }
  .product-col{
    width: 100%;
  }

  .product-tile{
    align-items: center;
    margin-bottom: 2em;
    width: 100%;
  }
  .group{
    height: auto;
    padding-bottom: 2em;
  }

}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbarContainer {
  /* background-color: red; */
  margin-top: 64px;
}

.MuiAppBar-root {
  background-color: white !important;
}

.MuiToolbar-root {
  color: black !important;
}

.logo-nav {
  margin-right: 0.5em;
}

.logoTitle {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
  color: black;
}

@media (max-width: 600px) {
  .navbarContainer {
    margin-top: 56px;
  }
}

/* .navbar {
  background: blue;
}

.navbarItem {
  color: yellow;
} */

.CompanyTitle,
.navBarItems {
  color: black;
  text-decoration: none;
  font-family: 'roboto';
}

.navBarItems {
  font-size: 0.8em;
  color: #666666;
  width: 100%;
  height: 100%;
  padding: 6px 20px !important;
  display: block;
}

.navBarItems.active {
  color: #11b267;
}

.testMobile.active {
  color: #11b267;
}

.MuiButtonBase-root {
  /* padding: 6px 20px !important; */
  box-sizing: border-box!important;
  padding: 0!important;
}
.MuiButtonBase-root:hover {
  background: none!important;
}

.full-page-container {
  background-color: rgb(216, 216, 216);
  /* width: 100vw; */
  min-height: calc(100vh - 64px);
  /* background-image: url('./assets/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; */
}

.cta-area {
  margin-left: 65%;
  padding-top: 5%;
  margin-right: 5%;
}

.main-title {
  font-size: 2.5em;
  font-weight: 800;
  padding-bottom: 0.3em;
}

.big-subtitle {
  font-size: 1.4em;
  font-weight: 500;
  margin-bottom: 30px;
}

.green-line {
  width: 20%;
  height: 5px;
  background-color: #11b267;
  margin-bottom: 30px;
}

.small-subtitle {
  font-size: 0.8em;
  color: #666666;
}




.testMobile {
  color: black;
  text-decoration: none;
  font-family: 'roboto';
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  justify-content: center!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.STOP {
  background-color: red;
  color: white;
}

.messagerie-container {
  display: flex;
  height: 100%;
}

.sidebar {
  width: 250px;
  background-color: #fff;
  border-right: 1px solid #ddd;
  padding: 10px;
}

.main {
  flex: 1;
  padding: 10px;
}

.compose-btn {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 10px;
}

.inbox-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.inbox-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.inbox-item.active {
  background-color: #eee;
}

.inbox-item-count {
  background-color: #ddd;
  color: #555;
  font-size: 14px;
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: auto;
}

.toolbar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.back-btn {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.message-details {
  margin-left: 10px;
}

.message-details-title {
  font-weight: bold;
  font-size: 20px;
}

.message-details-sender {
  font-size: 14px;
}

.message-body {
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.strong-tilt-move-shake {
  animation: tilt-n-move-shaking 0.15s;
  animation-iteration-count: 20;

}

.logoutIcon{
  width: 2.3em;
  margin-top: -6px;
}


@keyframes tilt-n-move-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }

  25% {
    transform: translate(3px, 3px) rotate(3deg);
  }

  50% {
    transform: translate(0, 0) rotate(0eg);
  }

  75% {
    transform: translate(-3px, 3px) rotate(-3deg);
  }

  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

/* .red-overlay {
  background-color: rgba(211, 26,26, 0.33);
  width: 100vw;
  height: calc(100vh);
  z-index: 9999;
  position: absolute;
  top: 0;
} */

.landing-area {
  background-image: url('../assets/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  height: calc(100vh - 64px);
}

.col-container {
  display: flex;
  justify-content: center;
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: white;
}

.columns {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.col {
  width: 20%;
}

.col-title {
  font-size: 2em;
  font-weight: 600;
  padding-bottom: 1.3em;
}

.col-subtitle {
  font-weight: 600;
  font-size: 1.2em;
  font-style: italic;
  padding-bottom: 1em;
}

.col-txt {
  font-size: 0.8em;
  color: #afafaf;
}

.col-link {
  color: #5fda84;
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
  padding-top: 0.5em;
  font-size: small;
}



@media (max-width: 600px) {
  .CompanyTitle {
    display: flex;
    justify-content: center;
  }

  .navBarItems {
    padding: 6px 20px !important;
  }

  .MuiToolbar-root {
    justify-content: space-between !important;
  }

  .MuiButtonBase-root {
    padding: 6px 20px !important;
    /* box-sizing: border-box!important; */
    /* padding: 0!important; */
  }
  

  .landing-area {
    height: calc(100vh - 56px);
    background-position: right;
  }

  .cta-area {
    margin: 0 5%;
  }

  .columns {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .col {
    width: 100%;
    margin-bottom: 5%;
  }

  .MuiBox-root {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .MuiList-root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
  }


}